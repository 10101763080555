









import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class extends Vue {
    @Prop({ type: String })
    title!: string | undefined;

    get id(){
        return this.$route.query?.id
    }

    get content() {
        return this.id ? (this.$route.meta as any).titleEdit: this.title ? this.title : (this.$route.meta as any).title;
    }
}
