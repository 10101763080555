import { Http } from "@/http"
import { Id, res_list, req_list } from '@/types/global'

export interface add_store{
    name:string
    cover:string|Array<String>
    province:string
    city:string
    region:string
    address:string
    phone:string,
    device:string,
    user:number,
    status:number,
    longitude:number | string
    latitude:number | string
    number:number,
    wx_amount:number,
    dy_amount:number,
    account:string,
    password:string,
    user_level:number,

}

export interface skill_data{
    begin:string
    end:string
    price:number
}

export interface share_data{
    start_amount:number
    amount:number
    end:string
    begin:string
}

export interface scheme{
    name:string
    person_limit:number
    make_amount:number
    content:string
}
export interface store extends add_store{
    id:Id
    create_time:string
    update_time:string
}
export interface setBox{
    cabinetId:Id,
    number:number
}

class HttpStore extends Http{
	get_list = (data:req_list) => {
		return this.get<res_list<store>>({...data})
	}

    get_info = (id:number) => {
        return this.get<store>({},`/admin/store/${id}/`)
    }

    create = (data:add_store) => {
        return this.post(data)
    }

    edit = ({id,...data}: store) => {
        return this.patch(data,`/admin/store/${id}/`)
    }

    del_one = (id:number) => {
        return this.delete(id)
    }
    // 柜子初始化
    set_box =(data:setBox) => {
        return this.post(data,`/admin/setbox/`)
    }
}

export const api_store =  new HttpStore('/admin/store/')
